<template>
	<div class="detail">
		<div class="album">
			<div class="swiper-wrapper">
				<div class="swiper-slide" style="text-align: center;" v-for="(item, index) in detail.pics" :key="index"><img class="bannerPic" preview="pic" style="max-width: 100%;max-height: 100%;" :src="item" /></div>
			</div>
		</div>
		<div class="main">
			<div class="goodsInfo">
				<div class="name">{{ detail.spuName }}</div>
				<div class="price">
					<span class="current">€{{ detail.price }}</span>
					<span class="origin">{{ detail.originPrice == '0.00' ? '' : '€' + detail.originPrice }}</span>
				</div>
				<!-- <div class="title">颜色:</div>
				<div class="color">
					<div v-for="val in color.list" :class="`${val.id == color.current.id ? 'active' : ''} item`" :style="`background: ${val.color};`" @click="color.current = val" ></div>
				</div>
				<div class="line"></div> -->
				<div class="title">{{ $t('goodDetail.specification') }}:</div>
				<div class="sizes">
					<div v-for="(val, index) in detail.skus" :key="index" :class="`item ${active.skuId == val.skuId ? 'active' : ''}`" @click="active.skuId = val.skuId">
						{{ val.specInfo }}
					</div>
				</div>
				<!-- <div class="line"></div> -->
				<div class="buttons">
					<div class="btn deep" @click="addCart">{{ $t('goodDetail.addCart') }}</div>
					<div class="btn" @click="$bus.$emit('addLike', detail)">{{ $t('goodDetail.addCollect') }}</div>
				</div>
				<div class="title">{{ $t('tip.share') }}:</div>
				<div class="share">
					<img class="item" src="../../assets/icon/facebook.png" />
					<img class="item" src="../../assets/icon/camera.png" />
					<!-- <img class="item" src="../../assets/icon/twitter.png" /> -->
				</div>
				<div class="title">{{ $t('goodDetail.detail') }}:</div>
				<div class="detail" v-html="detail.content"></div>
				<el-collapse>
					<el-collapse-item>
						<template slot="title">
							<div class="tip">
								<img src="../../assets/icon/cloth.png" />
								<span>{{ $t('tip.washTip') }}</span>
								<i class="el-icon-arrow-down"></i>
							</div>
						</template>
						<div class="content">
							<p>最高30°C温和洗涤</p>
							<p>最高温度110°C无蒸气熨烫</p>
							<p>请勿用滚筒洗衣机甩干</p>
							<p>请勿漂白</p>
							<p>请勿干洗</p>
						</div>
					</el-collapse-item>
					<el-collapse-item>
						<template slot="title">
							<div class="tip">
								<img src="../../assets/icon/car.png" />
								<span>{{ $t('tip.postTip') }}</span>
								<i class="el-icon-arrow-down"></i>
							</div>
						</template>
						<div class="content">
							<p>这是一段描述这是一段描述这是一段描述这是一段描述这是一段描述这是一段描述这是一段描述这是一段描述这是一段描述这是一段描述这是一段描述</p>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
		</div>
		<div class="hot">
			<div class="title">{{ $t('tip.detailRecommend') }}</div>
			<div class="goods">
				<div class="item" v-for="val in recommand"><GoodsItem :detail="val"></GoodsItem></div>
			</div>
		</div>
	</div>
</template>

<script>
import Api from '../../api/request.js';
import Swiper from 'swiper';
import GoodsItem from '../../components/Goods/listItem.vue';
export default {
	name: 'GoodsDetail',
	components: {
		GoodsItem
	},
	data() {
		return {
			color: {
				list: [
					{
						id: 1,
						color: '#000000'
					},
					{
						id: 2,
						color: '#E0E4E3'
					},
					{
						id: 3,
						color: '#7D5337'
					},
					{
						id: 4,
						color: '#BE2B35'
					}
				],
				current: {
					id: 1,
					color: '#000000'
				}
			},
			size: {
				list: [
					{
						id: 1,
						size: 'XS'
					},
					{
						id: 2,
						size: 'S'
					},
					{
						id: 3,
						size: 'M'
					},
					{
						id: 4,
						size: 'L'
					},
					{
						id: 5,
						size: 'XL'
					}
				],
				current: {
					id: 1,
					color: 'XS'
				}
			},
			detail: {},
			active: {
				skuId: ''
			},
			recommand: []
		};
	},
	created() {
		this.getDetail();
	},
	methods: {
		getDetail() {
			this.$dialog.loading();
			Promise.all([
				new Promise((resolve, reject) => {
					Api.Goods.detail({
						spuId: this.$route.query.id
					}).then(res => {
						this.detail = res.data.data;
						this.detail.pics.unshift(this.detail.cover);
						Api.Goods.recommand({
							spu_ids: res.data.data.recommendSpu
						}).then(res => {
							this.recommand = res.data.data;
							resolve();
						});
					});
				})
				// new Promise((resolve, reject) => {
				// 	Api.Index.recommand({
				// 		limit: 4,
				// 		label: 4
				// 	}).then((res) => {
				// 		if (res.data.data.list.length != 0) {
				// 			this.recommand = res.data.data.list;
				// 		}
				// 		resolve();
				// 	});
				// }),
			]).then(res => {
				var mySwiper = new Swiper('.album', {
					loop: true,
					pagination: {
						el: '.swiper-pagination'
					}
				});
				this.$dialog.close();
			});
		},
		addCart() {
			if (this.active.skuId == '') {
				this.$bus.$emit('addCart', this.detail.skus[0].skuId);
			} else {
				this.$bus.$emit('addCart', this.active.skuId);
			}
		}
	}
};
</script>

<style lang="less" scoped>
.album {
	height: 100vw;
	background: #ccc;
}

.main {
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	padding: 3vw;

	.goodsInfo {
		width: 100%;

		.name {
			font-size: 4vw;
			font-weight: bold;
		}

		.title {
			height: 10vw;
			font-size: 3.8vw;
			line-height: 10vw;
			font-weight: bold;
			margin-top: 6vw;
		}

		.line {
			height: 1px;
			background: #757575;
			margin-top: 3vw;
		}

		.price {
			.current {
				font-size: 3.5vw;
				font-weight: bold;
			}

			.origin {
				font-size: 3.2vw;
				font-weight: 400;
				margin-left: 18px;
				text-decoration: line-through;
			}
		}

		.color {
			display: flex;

			.item {
				width: 6vw;
				height: 6vw;
				border-radius: 50%;
				margin-right: 6vw;
				cursor: pointer;

				&.active {
					&::before {
						content: '';
						display: block;
						width: 4vw;
						height: 4vw;
						border: 0.5vw solid #fff;
						border-radius: 50%;
						margin: 0.5vw auto 0;
					}
				}
			}
		}

		.sizes {
			// display: flex;
			.item {
				font-size: 3vw;
				font-weight: bold;
				margin: 2vw 8vw 0 0;
				padding: 5px 10px;
				border-radius: 6px;
				border: 1px solid #fff;

				&.active {
					border-color: #000;
				}
			}
		}

		.buttons {
			display: flex;
			margin-top: 6vw;

			.btn {
				height: 10vw;
				padding: 0 2vw;
				background: #ffffff;
				border-radius: 6px;
				border: 1px solid #123178;
				text-align: center;
				line-height: 11vw;
				font-size: 12px;
				font-weight: 600;
				color: #123178;
				cursor: pointer;
				margin-right: 3vw;

				&.deep {
					background-color: #e8c9a0;
					border: 1px solid #e8c9a0;
					color: #fff;

					&:hover {
						background-color: #eecc77;
					}
				}

				&:hover {
					background-color: #021450;
					color: #fff;
				}
			}
		}

		.share {
			display: flex;

			.item {
				width: 24px;
				height: 24px;
				margin-right: 20px;
				cursor: pointer;
			}
		}

		.tip {
			display: flex;
			align-items: center;
			margin-top: 40px;
			cursor: pointer;

			& > img {
				width: 20px;
				height: 18px;
			}

			& > span {
				margin: 0 24px 0 16px;
				font-size: 14px;
				font-weight: 400;
			}

			& > i {
				font-size: 12px;
				font-weight: bolder;
			}
		}
	}
}

.hot {
	overflow: hidden;
	padding: 3vw;

	.title {
		height: 10vw;
		font-size: 3.8vw;
		line-height: 10vw;
		font-weight: bold;
		margin-top: 6vw;
	}

	.goods {
		display: flex;
		flex-wrap: wrap;

		.item {
			width: calc((100% - 6px) / 2);
			margin-right: 6px;
			margin-bottom: 15px;

			&:nth-child(2n) {
				margin-right: 0;
			}
		}
	}
}

/deep/.el-collapse {
	border: none;

	.el-collapse-item {
		margin-top: 20px;

		.el-collapse-item__header {
			border-bottom: none;

			.el-collapse-item__arrow {
				display: none;
			}
		}
	}

	.el-collapse-item__wrap {
		border-bottom: none;
	}

	.el-collapse-item__content {
		padding-top: 8vw;
	}
}
</style>
